import { Fragment, memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';

import messages from 'src/shared/app/authentication/l10n/logInL10n';
import DocumentTitle from 'src/shared/app/base/component/document/DocumentTitle';
import withDoubleDutch from 'src/shared/app/base/vendor/double-dutch/component/hoc/withDoubleDutch';

import LoginWizard from './LoginWizard';

const LoginMain = (): Node => {
  const intl = useIntl();
  return (
    <>
      <DocumentTitle>{intl.formatMessage(messages.title)}</DocumentTitle>
      <LoginWizard />
    </>
  );
};

export default memo(withDoubleDutch(LoginMain));
