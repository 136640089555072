import { useDispatch } from 'react-redux';

import usePushNotificationsManager from 'src/client/worker/hooks/usePushNotificationsManager';
import { useLogOutUserMutation } from 'src/shared/app/base/api/endpoint/usersEndpoint';
import useIsErrorReportingEnabled from 'src/shared/app/base/hook/useIsErrorReportingEnabled';
import { logoutPath } from 'src/shared/app/base/route/setting/routePaths';
import { unregisterSentryUserFromBrowser } from 'src/shared/app/base/util/sentryUtils';
import { removeTokenFromCookie } from 'src/shared/app/base/util/tokenUtils';

import { liveChatsActions } from 'plugin/live-chat/action/liveChatAction';

const useLogOut = () => {
  const dispatch = useDispatch();
  const isErrorReportingEnabled = useIsErrorReportingEnabled();
  const { unsubscribeFromWebPush } = usePushNotificationsManager();
  const [logout] = useLogOutUserMutation();
  return async () => {
    const { kill } = liveChatsActions;
    dispatch(kill);
    await unsubscribeFromWebPush();
    removeTokenFromCookie();

    if (isErrorReportingEnabled) {
      unregisterSentryUserFromBrowser();
    }

    await logout();
    window.location = logoutPath;
  };
};

export default useLogOut;
