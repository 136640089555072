import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  getEventForgotPasswordURL,
  getEventLoginPasswordLabel,
  getEventLoginPasswordLegend,
  getEventLoginPasswordPlaceholder,
  getEventLoginPasswordText,
  getEventPasswordFieldType,
} from '@braindate/domain/lib/event/util';
import {
  isMembershipOnboardingCompleted,
  isMembershipOptInCompleted,
} from '@braindate/domain/lib/membership/util';
import { isUserEmailValidated } from '@braindate/domain/lib/user/util';

import {
  createPassword,
  login,
  resetInitialUrl,
} from 'src/shared/app/authentication/action/base/authenticationActions';
import AuthenticationContainer from 'src/shared/app/authentication/component/AuthenticationContainer';
import AuthenticationPasswordForm from 'src/shared/app/authentication/component/base/AuthenticationPasswordForm';
import useRouteWithUrlParamsString from 'src/shared/app/authentication/hook/useRouteWithUrlParamsString';
import logInMessages from 'src/shared/app/authentication/l10n/logInL10n';
import {
  getAuthenticationUsername,
  getInitialUrl,
  isPasswordSet as passwordSet,
} from 'src/shared/app/authentication/selector/base/appAuthenticationBaseSelectors';
import { useCompleteOnboardingMutation } from 'src/shared/app/base/api/endpoint/membershipEndpoint';
import { useSendEmailVerificationEmailMutation } from 'src/shared/app/base/api/endpoint/usersEndpoint';
import { throwFormikSubmissionError } from 'src/shared/app/base/form/util/formUtil';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import {
  homeRoute,
  validateEmailRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import { isOnboardingCompletedOnDevice } from 'src/shared/app/onboarding/selector/uiOnboardingSelectors';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import useEventId from 'src/shared/domain/event/hook/useEventId';

const LoginPasswordForm = (): Node => {
  const intl = useIntl();
  const navigate = useNavigate();
  const event = useEvent();
  const dispatch = useDispatch();
  const initialUrl = useSelector(getInitialUrl);
  const isPasswordSet = useSelector(passwordSet);
  const { areEmailNotificationsEnabled } = useFeatureDecisions();
  const { locale } = intl;
  // $FlowIssue: type only defined for public events
  const type = getEventPasswordFieldType(event);
  const note = getEventLoginPasswordText(event, locale);
  const label = getEventLoginPasswordLabel(event, locale);
  const legend = getEventLoginPasswordLegend(event, locale);
  const eventId = useEventId();
  const homePath = useRouteWithUrlParamsString(homeRoute);
  const placeholder = getEventLoginPasswordPlaceholder(event, locale);
  const forgotPasswordURL = getEventForgotPasswordURL(event);
  const [validateEmailPath] = useRoutes(validateEmailRoute);
  const isOnboardingCompleted = useSelector(isOnboardingCompletedOnDevice);
  const username = useSelector(getAuthenticationUsername);
  const [sendEmailVerificationEmail] = useSendEmailVerificationEmailMutation();
  const [completeOnboarding] = useCompleteOnboardingMutation();

  const handleSubmit = async ({ password }: Record<string, any>) => {
    const action = isPasswordSet ? login : createPassword;

    try {
      const { user, membership } = await dispatch(
        action(username, password, eventId),
      );

      if (areEmailNotificationsEnabled && !isUserEmailValidated(user)) {
        sendEmailVerificationEmail(user.id);
        return navigate(validateEmailPath);
      }

      if (isOnboardingCompleted) {
        await completeOnboarding();
      }

      if (
        isMembershipOnboardingCompleted(membership) &&
        isMembershipOptInCompleted(membership) &&
        initialUrl
      ) {
        dispatch(resetInitialUrl());
        navigate(initialUrl);
      }

      navigate(homePath);
    } catch (e) {
      throwFormikSubmissionError(e);
    }
  };

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  const legendElt =
    legend ||
    intl.formatMessage(
      logInMessages[isPasswordSet ? 'passwordLegend' : 'newPasswordLegend'],
    );
  return (
    <AuthenticationContainer
      title={intl.formatMessage(logInMessages.title)}
      legend={legendElt}
    >
      <AuthenticationPasswordForm
        type={type}
        label={
          isPasswordSet
            ? label
            : intl.formatMessage(logInMessages.newPasswordLabel)
        }
        placeholder={placeholder}
        note={note}
        submitCta={intl.formatMessage(logInMessages.passwordSubmit)}
        handleSubmit={handleSubmit}
        isPasswordSet={isPasswordSet}
        forgotPasswordURL={forgotPasswordURL && forgotPasswordURL}
      />
    </AuthenticationContainer>
  );
};

export default memo(LoginPasswordForm);
