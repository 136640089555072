import { memo, useEffect } from 'react';
import type { Node } from 'react';

import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { Divider } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useId, VisuallyHidden } from 'react-aria';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import LazyLoginSupportMain from 'src/shared/app/authentication/component/support/login/LazyLoginSupportMain';
import messages from 'src/shared/app/authentication/l10n/authenticationL10n';
import { usernameFieldMaxLength } from 'src/shared/app/authentication/settings/authenticationSettings';
import FormikFormGroup from 'src/shared/app/base/component/data-entry/form/FormikFormGroup';
import Markdown from 'src/shared/app/base/component/text/Markdown';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import {
  loginLinkRoute,
  loginSupportRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import LinkButton from 'src/shared/ui/component/link/LinkButton';
import NoteText from 'src/shared/ui/component/text/NoteText';

import styles from './AuthenticationBase.style';
import AuthenticationFormHeader from './AuthenticationFormHeader';
import AuthenticationSignupButton from './AuthenticationSignupButton';

type Props = {
  type: string | null | undefined;
  initialUsername: string | null | undefined;
  label: string | null | undefined;
  placeholder?: string;
  note: string | null | undefined;
  submitCta: string;
  handleSubmit: (arg0: Record<string, any>) => Promise<any>;
};
const useStyles = createUseThemeStyles(styles);

const AuthenticationUsernameForm = ({
  type: propType,
  initialUsername,
  label,
  placeholder,
  note,
  submitCta,
  handleSubmit,
}: Props): Node => {
  const { areEmailNotificationsEnabled } = useFeatureDecisions();
  const type = propType || 'email';
  let usernameSchema = Yup.string().required();

  if (type === 'email') {
    usernameSchema = usernameSchema.email();
  }

  const schema = Yup.object().shape({
    username: usernameSchema,
  });

  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */
  const submitErrorId = useId();
  const intl = useIntl();
  const [loginSupportPath, loginLinkPath] = useRoutes(
    loginSupportRoute,
    loginLinkRoute,
  );

  /*
   |----------------------------------------------------------------------------
   | Effects
   |----------------------------------------------------------------------------
   */
  useEffect(() => {
    LazyLoginSupportMain.preload();
  }, []);

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  const legendId = 'username-desc';
  const noteElt = note && (
    <NoteText layoutClass={classes.footer}>
      <Markdown>{note}</Markdown>
    </NoteText>
  );
  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          username: initialUsername,
        }}
        validationSchema={schema}
        validateOnChange={false}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          setStatus(null);
          return handleSubmit(values).catch(({ errors }) => {
            setSubmitting(false);
            const { root, username } = errors || {};

            if (root || username) {
              setStatus({
                root,
                username,
              });
            }
          });
        }}
      >
        {({ isSubmitting, status, values }) => (
          <Form className={classes.form} noValidate>
            {status?.root && (
              <AuthenticationFormHeader withError>
                {status?.root}
              </AuthenticationFormHeader>
            )}
            <Field
              id="username"
              name="username"
              type={type}
              component={FormikFormGroup}
              componentOpts={{
                label: label || intl.formatMessage(messages.emailLabel),
              }}
              error={status?.username}
              maxLength={usernameFieldMaxLength}
              placeholder={placeholder}
              required
              autoFocus
              aria-describedby={legendId}
            />
            <Field
              id="password"
              name="password"
              type={type}
              component={FormikFormGroup}
              componentOpts={{
                label: intl.formatMessage(messages.passwordLabel),
                className: classes.passwordInputHidden,
              }}
              autoComplete="current-password"
              tabIndex={-1}
              maxLength={usernameFieldMaxLength}
              placeholder={placeholder}
            />

            <div className={classes.support}>
              <Link to={loginSupportPath}>
                {intl.formatMessage(messages.troubleLoggingIn)}
              </Link>
            </div>

            <div className={classes.submit}>
              {!values.username && (
                <VisuallyHidden id={submitErrorId}>
                  {intl.formatMessage(messages.errorMessage)}
                </VisuallyHidden>
              )}

              <PrimaryButton
                layoutClass={classes.nextButton}
                aria-disabled={!values.username}
                isSubmitting={isSubmitting}
                aria-describedby={!values.username ? submitErrorId : undefined}
              >
                {submitCta}
              </PrimaryButton>
            </div>

            <AuthenticationSignupButton />
            {noteElt}

            {areEmailNotificationsEnabled ? (
              <>
                <div className={classes.separator}>
                  <Divider />
                  <span>{intl.formatMessage(messages.or)}</span>
                  <Divider />
                </div>

                <LinkButton
                  to={loginLinkPath}
                  variant="secondary"
                  layoutClass={classes.nextButton}
                  aria-disabled={!values.username}
                  isSubmitting={isSubmitting}
                  aria-describedby={
                    !values.username ? submitErrorId : undefined
                  }
                >
                  <InsertLinkIcon />
                  {intl.formatMessage(messages.sendLoginLink)}
                </LinkButton>
              </>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default memo<Props>(AuthenticationUsernameForm);
