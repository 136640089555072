import { memo } from 'react';
import type { Node } from 'react';

import Markdown from 'src/shared/app/base/component/text/Markdown';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import InstructionText from 'src/shared/ui/component/text/InstructionText';

import styles from './AuthenticationBase.style';

type Props = {
  id?: string;
  withError?: boolean;
  children: string;
};
const useStyles = createUseThemeStyles(styles);

const AuthenticationFormHeader = ({ id, withError, children }: Props): Node => {
  const classes = useStyles();
  return (
    <div
      className={classes.header}
      id={id || null}
      role={withError ? 'alert' : undefined}
    >
      <InstructionText left withError={withError}>
        <Markdown>{children || ''}</Markdown>
      </InstructionText>
    </div>
  );
};

export default memo<Props>(AuthenticationFormHeader);
