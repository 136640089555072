import * as types from './uiAuthenticationActionTypes';

export function resetAuthenticationLoginSection(): Record<string, any> {
  return {
    type: types.RESET_AUTHENTICATION_LOGIN_SECTION,
  };
}
export function showAuthenticationLoginPasswordSection(): Record<string, any> {
  return {
    type: types.SHOW_AUTHENTICATION_LOGIN_PASSWORD_SECTION,
  };
}
