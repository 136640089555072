import { useEffect } from 'react';
import type { Node } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { getEventId } from '@braindate/domain/lib/event/util';
import { getDisplayName } from '@braindate/util/lib/react/hoc/hocUtil';

import { loginWithDoubleDutch } from 'src/shared/app/authentication/action/base/authenticationActions';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import { homeRoute } from 'src/shared/app/base/route/setting/routeSettings';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import useEvent from 'src/shared/domain/event/hook/useEvent';

export default function withDoubleDutch(WrappedComponent: any): any {
  const WithDoubleDutchComponent = (props: Record<string, any>): Node => {
    const event = useEvent();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [homePath] = useRoutes(homeRoute);
    const { isDoubleDutchIntegrationEnabled } = useFeatureDecisions();
    useEffect(() => {
      if (isDoubleDutchIntegrationEnabled) {
        if (window.DD) {
          initDoubleDutch();
        } else if (window.loadJS) {
          const script = window.loadJS(
            'https://cdn.rawgit.com/doubledutch/sdk/master/DDEventsSDK.js',
            initDoubleDutch,
          );

          script.onerror = () => {
            // TODO: handle error
          };
        }
      }
    }, []);

    const initDoubleDutch = () => {
      const { DD } = window;
      DD.Events.onReady(() => {
        DD.Events.getSignedAPIAsync('users/me', '', (signedUrl, authHeader) => {
          dispatch(
            loginWithDoubleDutch(getEventId(event), authHeader, signedUrl),
          )
            .then(() => navigate(homePath))
            .catch(() => {
              // TODO
            });
        });
      });
    };

    return <WrappedComponent {...props} />;
  };

  WithDoubleDutchComponent.displayName = `WithDoubleDutchComponent(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithDoubleDutchComponent;
}
