import { Field, Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { getUserId, getUserUsername } from '@braindate/domain/lib/user/util';

import AuthenticationFormHeader from 'src/shared/app/authentication/component/base/AuthenticationFormHeader';
import AuthenticationValidateEmailFormLoading from 'src/shared/app/authentication/component/base/AuthenticationValidateEmailFormLoading';
import useLogOut from 'src/shared/app/authentication/hook/useLogout';
import messages from 'src/shared/app/authentication/l10n/validateEmailL10n';
import { useSendEmailVerificationEmailMutation } from 'src/shared/app/base/api/endpoint/usersEndpoint';
import FormikFormGroup from 'src/shared/app/base/component/data-entry/form/FormikFormGroup';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import { loginRoute } from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import useSelf from 'src/shared/domain/user/hook/useSelf';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';

import styles from './AuthenticationBase.style';

const useStyles = createUseThemeStyles(styles);

const AuthenticationValidateEmailForm = () => {
  const self = useSelf();
  const intl = useIntl();
  const classes = useStyles();
  const [loginPath] = useRoutes(loginRoute);
  const logOut = useLogOut();
  const [sendEmailVerificationEmail, { isLoading }] =
    useSendEmailVerificationEmailMutation();

  const logout = (e) => {
    e.preventDefault();
    return logOut();
  };

  const handleSubmit = async (_, { setErrors }) => {
    if (!self) return;

    try {
      await sendEmailVerificationEmail(getUserId(self));
    } catch (e) {
      setErrors({
        username: e.message,
      });
    }

    // endpoint is too fast
    await new Promise((resolve) => {
      setTimeout(resolve, 800);
    });
  };

  if (!self) {
    return <AuthenticationValidateEmailFormLoading />;
  }

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          username: getUserUsername(self),
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={classes.form} noValidate>
            {!isSubmitting && (
              <AuthenticationFormHeader>
                {intl.formatMessage(messages.linkSent)}
              </AuthenticationFormHeader>
            )}

            <Field
              id="username"
              name="username"
              type="text"
              component={FormikFormGroup}
              componentOpts={{
                label: intl.formatMessage(messages.email),
              }}
              disabled
              required
              autoFocus
            />

            <div className={classes.submit}>
              <PrimaryButton
                layoutClass={classes.nextButton}
                isSubmitting={isSubmitting || isLoading}
                type="submit"
              >
                {intl.formatMessage(messages.resend)}
              </PrimaryButton>
            </div>

            <div className={classes.signup}>
              <p>
                <FormattedMessage
                  {...messages.signOut}
                  values={{
                    a(text) {
                      return (
                        <Link to={loginPath} onClick={logout}>
                          {text}
                        </Link>
                      );
                    },
                  }}
                />
              </p>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AuthenticationValidateEmailForm;
